export const BASE_API_URL = process.env.REACT_APP_API_BASE_URL as string;

export const SOCKET_URL = process.env.REACT_APP_SOCKET_URL as string;

export const currencies = { EUR: 'euro', USD: 'dollar' };

export const levelReligiousEducations: any = {
  NONE: 'Aucun',
  BEGINNER: 'Débutant(e)',
  INTERMEDIATE: 'Intermédiaire',
  ADVANCED: 'Avancé(e)',
  SENIOR: 'Confirmé(e)',
  NOT_PRONOUNCED: 'Ne se prononce pas',
};

export const maritalStatus = {
  NONE: 'Aucun',
  NEVER_MARRIED: 'Jamais marié',
  MARRIED: 'Marié',
  DIVORCED: 'Divorcé',
  WIDOW: 'Voeuf',
  NOT_PRONOUNCED: 'Non prononcé',
};

export const femaleDressCodes = {
  notWearHeadscarf: 'Ne porte pas encore le voile',
  wearAbaya: 'Porte le abaya',
  wearHijab: 'Porte le Hijab',
  wearJilbab: 'Porte le Jilbab',
  wearNiqab: 'Porte le Niqab',
  wearTurban: 'Porte le turban',
};

export const listItemsPerPage = [
  {
    label: 5,
    value: 5,
  },
  {
    label: 10,
    value: 10,
  },
  {
    label: 25,
    value: 25,
  },
  {
    label: 50,
    value: 50,
  },
  {
    label: 75,
    value: 75,
  },
  {
    label: 100,
    value: 100,
  },
];

export enum ROLE {
  ADMIN = 'ADMINISTRATOR',
  MODO = 'MODERATOR',
  IMAM = 'IMAM',
  SPEAKER = 'SPEAKER',
  SUPERVISOR = 'SUPERVISOR',
}

export enum EPaymentStatus {
  PENDING = 'PENDING',
  SUCCEED = 'SUCCEED',
  FAILED = 'FAILED',
  CANCELED = 'CANCELED',
  REFUNDED = 'REFUNDED',
  DELETED_BY_ADMIN = 'DELETED_BY_ADMIN',
  DELETED_BY_USER = 'DELETED_BY_USER',
  EXPIRED = 'EXPIRED',
}

export enum MS_ENDPOINT {
  AUTH = 'auth-s',
  BOAUTH = 'auth-s/bo',
  PROFILE = 'profile-s/bo',
  PARAMS = 'param-s',
  PAYMENT = 'payment-s/bo',
  CHAT = 'chat-s/bo',
  NOTIF = 'notif-s/bo'
}
