import { createSlice } from '@reduxjs/toolkit';

import * as settingsActions from './settings.actions';
import settingsAsyncActions from './settings.asyncActions';

import { ISettingsInitialState } from './settings.interfaces';

const initialState: ISettingsInitialState = {
  ethnicities: null,
  businessHours: null,
  plans: null,
  paidServices: null,
  appInterface: null,
  cgu: {
    _id: '',
    content: { blocks: [] },
  },
};

export const settingsSlice = createSlice({
  name: 'settings',
  initialState,
  reducers: {
    ...settingsActions,
  },
  extraReducers(builder) {
    settingsAsyncActions(builder);
  },
});

export const settingsSliceActions = settingsSlice.actions;

export default settingsSlice.reducer;
