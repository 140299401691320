import { PayloadAction } from "@reduxjs/toolkit";
import { ISettingsInitialState } from "./settings.interfaces";
import { TAppInterfaceSettings } from "@/features/backoffice/types";

export const setBusinessHourAction = (
  state: ISettingsInitialState,
  action: PayloadAction<{ name: string; id: string; value: string }>
) => {
  const findIndex = state.businessHours?.result.findIndex(
    (timeSlot) => timeSlot._id === action.payload.id
  ) as number;

  state.businessHours!.result[findIndex] = {
    ...state.businessHours?.result[findIndex!]!,
    [action.payload.name]: action.payload.value,
  };
};

export const setBusinessHolydayAction = (
  state: ISettingsInitialState,
  action: PayloadAction<string>
) => {
  const findIndex = state.businessHours?.result.findIndex(
    (timeSlot) => timeSlot._id === action.payload
  );

  console.log(action.payload)

  state.businessHours!.result[findIndex!] = {
    ...state.businessHours?.result[findIndex!]!,
    isHoliday: !state.businessHours?.result[findIndex!].isHoliday,
  };
};

export const setAppInterfaceSettings = (
  state: ISettingsInitialState,
  action: PayloadAction<TAppInterfaceSettings>
) => {
  state.appInterface = action.payload
}

export const deletePlanAction = (
  state: ISettingsInitialState,
  action: PayloadAction<string>
) => {
  if (state.plans) {
    state.plans.data.result = state.plans.data.result.filter(
      (item) => item._id !== action.payload
    );
  }
};

export const deletePaidServiceAction = (
  state: ISettingsInitialState,
  action: PayloadAction<string>
) => {
  if (state.paidServices) {
    state.paidServices.data.result = state.paidServices.data.result.filter(
      (item) => item._id !== action.payload
    );
  }
};
