import { ActionReducerMapBuilder } from "@reduxjs/toolkit";

import { IImamSpeakersInitialState } from "./imamSpeakers.interfaces";

import {
  getImamsSpeakersAsync,
  createImamsSpeakerAsync,
  updateImamsSpeakerAsync,
  deleteImamsSpeakerAsync,
} from "./imamSpeakers.services";
import { ROLE } from "@/config/constants";

const asyncActions = (
  builder: ActionReducerMapBuilder<IImamSpeakersInitialState>
) => {
  builder.addCase(getImamsSpeakersAsync.fulfilled, (state, action) => {
    state.listImamSpeakers = action.payload;
  });

  builder.addCase(createImamsSpeakerAsync.fulfilled, (state, action) => {
    if (state.listImamSpeakers) {
      state.listImamSpeakers.data.result.push(action.payload.data);
      state.listImamSpeakers.data.count++;
    }
  });

  builder.addCase(updateImamsSpeakerAsync.fulfilled, (state, action) => {
    if (state.listImamSpeakers) {
      const index = state.listImamSpeakers.data.result.findIndex(
        (listImamSpeaker) => listImamSpeaker._id === action.payload.data._id
      );

      state.listImamSpeakers.data.result[index] = {
        ...state.listImamSpeakers.data.result[index],
        ...action.payload.data,
        role: action.payload.data.role as ROLE.IMAM | ROLE.SPEAKER,
      };
    }
  });

  builder.addCase(deleteImamsSpeakerAsync.fulfilled, (state, action) => {
    if (state.listImamSpeakers) {
      const index = state.listImamSpeakers.data.result.findIndex(
        (listImamSpeaker) => listImamSpeaker._id === action.payload.data
      );

      state.listImamSpeakers.data.result.splice(index, 1);
      state.listImamSpeakers.data.count++;
    }
  });
};

export default asyncActions;
