import { ActionReducerMapBuilder } from "@reduxjs/toolkit";

import { IAuthInitialState } from "./auth.interfaces";

import { tryLogin } from "./auth.services";
import { errorInitialValue } from "./auth.slice";
import {decodeToken} from "react-jwt";

const authAsyncActions = (
  builder: ActionReducerMapBuilder<IAuthInitialState>
) => {
  builder.addCase(tryLogin.pending, (state) => {
    state.loading = "pending";
    state.errors = errorInitialValue;
  });

  builder.addCase(tryLogin.fulfilled, (state, action) => {
    state.loading = "idle";
    state.errors = errorInitialValue;
    state.user = (decodeToken(action.payload.data) as any)?.sub;
    state.token = action.payload.data;
  });

  builder.addCase(tryLogin.rejected, (state, action) => {
    state.loading = "failed";
    state.errors = action.payload;
  });
};

export default authAsyncActions;
