import { RouteObject } from 'react-router-dom';

import { lazyImport } from '../utils/lazyImport';

const { BackOfficeRoutes } = lazyImport(
  () => import('../features/backoffice'),
  'BackOfficeRoutes'
);

export const protectedRoutes: RouteObject[] = [
  {
    path: '*',
    element: <BackOfficeRoutes />,
  },
];
