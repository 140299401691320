import { axios } from '@/lib/axios';
import { TPlanInitialValues } from '../../containers/Settings/Plans/Plans.container';

import {
  IAPIDeletePlan,
  IAPIGetPlans,
  IAPIPatchPlan,
  IAPIPostPlan,
  IApiGet,
} from '../../types';

export const getPlans = (): Promise<IAPIGetPlans> => {
  return axios.get('/payment-s/bo/api/v1/payments-plans-subscriptions');
};

export const createPlans = (
  datas: TPlanInitialValues
): Promise<IAPIPostPlan> => {
  return axios.post('/payment-s/bo/api/v1/payments-plans-subscriptions', {
    ...datas,
  });
};

export const editPlan = (
  id: string,
  datas: Partial<TPlanInitialValues>
): Promise<IApiGet<IAPIPatchPlan>> => {
  const { _id, ...rest } = datas;
  return axios.patch(`/payment-s/bo/api/v1/payments-plans-subscriptions/${id}`, {
    ...rest,
  });
};

export const togglePlanActiveness = (id: string): Promise<IAPIDeletePlan> => {
  return axios.delete(
    `/payment-s/bo/api/v1/payments-plans-subscriptions/${id}/activate-or-deactivate`
  );
};
