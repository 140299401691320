import { IAPIResponseLogin } from "../types/interfaces";
import { axios } from "@/lib/axios";

export const login = (
    identifier: string,
  password: string
): Promise<IAPIResponseLogin> => {
  return axios.post("/auth-s/api/v1/auth/p/bo/sign-in", {
    identifier,
    password,
  });
};

export const askOTP = (
  email: string
): Promise<{
  code: string;
  message: string;
}> => {
  return axios.post("/auth-s/api/v1/auth/p/forgot-password", {
    email,
  });
};

export const changePassword = ({
  email,
  token,
  password,
}: {
  email: string;
  token: string;
  password: string;
}): Promise<{
  code: string;
  message: string;
}> => {
  return axios.post("/auth-s/api/v1/auth/p/reset-password", {
    email,
    token,
    password,
  });
};

export const verifyEmail = ({
  email,
  token,
  password,
}:{
  email: string;
  token: string;
  password: string;
}): any => {
  return axios.post("/auth-s/api/v1/auth/p/bo/verify-email", {
    email,
    token,
    password,
  });
}


export const logout = () => {
  return axios.post(`/auth-s/api/v1/auth/logout`);
}