
import { axios } from "@/lib/axios";
import {
  IAPIGetBusinessHours,
  IAPIGetModerationStatus,
  TTimeSlot,
} from "../../types";

export const getTimeSlots = (): Promise<IAPIGetBusinessHours> => {
  return axios.get("/param-s/api/v1/parameters/app-plannings");
};

export const updateTimeSlots = ({data} : {data : TTimeSlot[]}
): Promise<IAPIGetBusinessHours> => {
  return axios.post(`/param-s/api/v1/parameters/app-plannings/update`, data);
};

export const getModerationStatus = (): Promise<IAPIGetModerationStatus> => {
  return axios.get("/param-s/api/v1/parameters/app-plannings");
};

export const getOpeningStatus = (): Promise<{
  data: boolean, 
  message: string
}> => {
  return axios.get("/param-s/api/v1/parameters/app-plannings/is-open");
}
