import { axios } from "@/lib/axios";
import {TFaqInitialValues} from "../../containers/ImamQR/ImamQR.container";

import {IAPIDeleteFaq, IAPIGetFaqs, IAPIPatchFaq, IAPIPostFaq,} from "../../types";

export const getFaqs = (): Promise<IAPIGetFaqs> => {
  return axios.get("/param-s/api/v1/parameters/faqs");
};

export const createFaq = (datas: TFaqInitialValues): Promise<IAPIPostFaq> => {
  return axios.post("/param-s/api/v1/parameters/faqs", {
    ...datas,
  });
};

export const updateFaq = (
  id: string,
  data: TFaqInitialValues
): Promise<IAPIPatchFaq> => {
  return axios.patch(`/param-s/api/v1/parameters/faqs/${id}`, {
    ...data,
  });
};

export const deleteFaq = (id: string): Promise<IAPIDeleteFaq> => {
  return axios.delete(`/param-s/api/v1/parameters/faqs/${id}`);
};
