import { TReducerError } from "@/types/app";
import {createSlice} from "@reduxjs/toolkit";
import asyncActions from "./member.asyncActions";

import { IMembersInitialState } from "./member.interfaces";
import { IAppInitialState } from "../app/app.interfaces";

export const errorInitialValue: TReducerError = {
  message: "",
  value: null,
};

const initialState: IMembersInitialState = {
  listProfiles: null,
  listDeletedAccounts: null,
  listPendingProfiles: null,
  listReportedAccounts: null,
  nbUnhandledReports: 0,
  loading: "idle",
  errors: {
    message: "",
    value: null,
  },
};

export const memberSlice = createSlice({
  name: "member",
  initialState,
  reducers: {
    newReport : (state: IMembersInitialState) => {
      if (state.nbUnhandledReports <= 0) return;
      state.nbUnhandledReports += 1;
    },

    handlePendingProfiles: (state: IMembersInitialState, action) => {
      if (state.listPendingProfiles?.data.result) {
        state.listPendingProfiles.data.result = state.listPendingProfiles.data.result.filter(
          (profile) => profile._id !== action.payload
        );
        state.listPendingProfiles.data.paginationResult.count -= 1;
        state.listPendingProfiles.data.paginationResult.totalCount -= 1;
      }
    }
  },
  extraReducers(builder) {
    asyncActions(builder);
  },
});

export const { newReport, handlePendingProfiles } = memberSlice.actions;

export const memberSliceActions = memberSlice.actions;

export default memberSlice.reducer;
