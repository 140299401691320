import { createSlice } from "@reduxjs/toolkit";
import { ITmpInterface } from "./tmp.interface";

const initialState: ITmpInterface = {
    email: '',
};

export const tmpSlice = createSlice({
    name: 'tmp',
    initialState,
    reducers: {
        updateEmail(state, action) {
            state.email = action.payload;
        }
    },
})

export const { updateEmail } = tmpSlice.actions

export default tmpSlice.reducer