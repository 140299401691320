import { axios } from "@/lib/axios";
import { TAdminInitialValues } from "../../pages/staff/Team.page";

import {
  IAPIDeleteResponseDeleteStaff,
  IAPIGetAdminStatsMessages,
  IAPIGetAdminStatsProfiles,
  IAPIGetAdminStatsResponse,
  IAPIGetStaffTotalOnlines, IAPIPatchResponseResendEmail,
  IAPIPatchResponseUpdateStaff,
  IAPIPostResponseAddStaff,
  IAPIResponseGetAllStaffs,
} from "../../types";

export const getAllStaffs = (
  {
    limit,
    page
  } : {
    limit?: number;
    page?: number;
  } = {}
): Promise<IAPIResponseGetAllStaffs> => {
  const params = limit && page ? { limit, page } : undefined;
  return axios.get("/auth-s/bo/api/v1/accounts/staffs", { params });
};

export const getTotalOnlines = (): Promise<IAPIGetStaffTotalOnlines> => {
  return axios.get("/auth-s/bo/api/v1/accounts/staffs/total-onlines");
}

export const getAdminStatsDashboard = (datas: {
  adminId: string;
  startDate?: string;
  endDate?: string;
}): Promise<IAPIGetAdminStatsResponse> => {
  return axios.get(`/profile-s/bo/api/v1/profiles/${datas.adminId}/validations/stats`, {
    params: {
      startDate: datas.startDate,
      endDate: datas.endDate,
    },
  });
};
export const getMessagesStatsDashboard = (datas: {
  adminId: string;
  startDate?: string;
  endDate?: string;
}): Promise<IAPIGetAdminStatsResponse> => {
  return axios.get(`/chat-s/bo/api/v1/messages/${datas.adminId}/messages/stats`, {
    params: {
      startDate: datas.startDate,
      endDate: datas.endDate,
    },
  });
};

export const getAdminStatsProfiles = (datas: {
  adminId: string;
  page?: number;
  limit?: number;
  status?: "VALIDATED" | "REJECTED" | "PENDING";
  startDate?: string;
  endDate?: string;
  search?: string;
}): Promise<IAPIGetAdminStatsProfiles> => {
  return axios.get(`/profile-s/bo/api/v1/profiles/${datas.adminId}/validations`, {
    params: {
      page: datas.page,
      limit: datas.limit,
      status: datas.status,
      startDate: datas.startDate,
      endDate: datas.endDate,
      searchTerm: datas.search,
    },
  });
};

export const getAdminStatsMessages = (datas: {
  adminId: string;
  page?: number;
  limit?: number;
  filter?: "VALIDATED" | "REJECTED";
  startDate?: string;
  endDate?: string;
  search?: string;
}): Promise<IAPIGetAdminStatsMessages> => {
  return axios.get(`/chat-s/bo/api/v1/messages/${datas.adminId}/messages`, {
    params: {
      page: datas.page,
      limit: datas.limit,
      status: datas.filter,
      startDate: datas.startDate,
      endDate: datas.endDate,
      search: datas.search,
    },
  });
};

export const addStaff = (
  data: TAdminInitialValues
): Promise<IAPIPostResponseAddStaff> => {
  return axios.post("/auth-s/bo/api/v1/accounts/staffs", {
    ...data,
  });
};

export const updateStaff = (
  id: string,
  data: TAdminInitialValues
): Promise<IAPIPatchResponseUpdateStaff> => {
  return axios.patch(`/auth-s/bo/api/v1/accounts/staffs/${id}`, {
    ...data,
  });
};

export const deleteStaff = (
  id: string
): Promise<IAPIDeleteResponseDeleteStaff> => {
  return axios.delete(`/auth-s/bo/api/v1/accounts/staffs/${id}`);
};

export const resendEmail = (
    id: string
): Promise<IAPIPatchResponseResendEmail> => {
  return axios.patch(`/auth-s/bo/api/v1/accounts/${id}/resend-activation-email`);
}


export const updateStaffPassword  = ({
  id,
  password,
}: {
  id: string;
  password: string;
}): Promise<{
  code: string;
  message: string;
}> => {
  return axios.patch(`/auth-s/bo/api/v1/accounts/staffs/${id}/change-password`, {
    password,
  });
};

export const getModerationStatusModerator = (): Promise<{data: boolean, message: string}> => {
  return axios.get("/auth-s/bo/api/v1/accounts/staffs/status");
};

export const toggleStaffStatus = (status: boolean): any => {
  return axios.patch("/auth-s/bo/api/v1/accounts/staffs/toggle-status",{
    status
  });
}
