import { axios } from "@/lib/axios";
import {IAPIGetReports, IAPIPatchReportMessage, TGender} from "../../types";

export const getAllReports = ({
  limit,
  page,
}: {
  limit?: number;
  page?: number;
} = {}): Promise<IAPIGetReports> => {
  return axios.get("/profile-s/bo/api/v1/reports", {
    params: {
      limit,
      page,
    },
  });
};

export const getFilteredReports = ({
  gender,
  startDate ,
  endDate ,
  reporter,
  defendant,
  isHandled,
  limit,
  page,
}: {
  startDate ?: string;
  endDate ?: string;
  gender?: TGender;
  reporter?: string;
  defendant?: string;
  isHandled ?: boolean;
  limit?: number;
  page?: number;
} = {}): Promise<IAPIGetReports> => {
  return axios.get("/profile-s/bo/api/v1/reports", {
    params: {
      gender,
      startDate ,
      endDate ,
      reporter,
      defendant,
      isHandled,
      limit,
      page,
    },
  });
};

export const getNbUnhandledReports: () => Promise<{
  data: number,
  message: string,
}> = () => {
  return axios.get("/profile-s/bo/api/v1/reports/nb-unhandled");
}

export const sendReportMessage = ({
  reportId,
  receiver,
  subject,
  message,
}: {
  reportId: string;
  receiver: string;
  subject: string;
  message: string;
}): Promise<IAPIPatchReportMessage> => {
  return axios.patch(`/notif-s/bo/api/v1/notifications/${reportId}/report/send-message`, {
    // report: reportId,
    // receiver,
    subject,
    message,
  });
};

export const markAsHandled = (id: string) => {
  return axios.patch(`/profile-s/bo/api/v1/reports/${id}/mark-as-handled`);
}
