import { axios } from "@/lib/axios";
import { TLiveInitialValues } from "../../containers/ListLives/ListLives.container";

import {
  IAPIDeleteResponseUpdateLive,
  IAPIGetResponseGetLives,
  IAPIPatchResponseUpdateLive,
  IAPIPostResponseAddLive,
} from "../../types/interfaces/api/lives";

export const getAllLives = (): Promise<IAPIGetResponseGetLives> => {
  return axios.get("/param-s/api/v1/parameters/live-sessions");
};

export const createLive = (
  data: TLiveInitialValues
): Promise<IAPIPostResponseAddLive> => {
  return axios.post("/param-s/api/v1/parameters/live-sessions", {
    ...data,
  });
};

export const updateLive = (
  id: string,
  data: TLiveInitialValues
): Promise<IAPIPatchResponseUpdateLive> => {
  return axios.patch(`/param-s/api/v1/parameters/live-sessions/${id}`, {
    ...data,
  });
};

export const deleteLive = (
  id: string
): Promise<IAPIDeleteResponseUpdateLive> => {
  return axios.delete(`/param-s/api/v1/parameters/live-sessions/${id}`);
};
