import { axios } from "@/lib/axios";
import {
  IApiGet,
  IAPIResponseGetModerationTemplate,
  IAPIResponsePostModerationTemplate,
} from "../../types";

export const getModerationTemplates =
  (): Promise<IAPIResponseGetModerationTemplate> => {
    return axios.get("/param-s/api/v1/parameters/moderation-templates");
  };

export const postModerationTemplate = (
    moderationText: string
): Promise<IAPIResponsePostModerationTemplate> => {
  return axios.post("/param-s/api/v1/parameters/moderation-templates", {
    moderationText,
  });
};

export const patchModerationTemplate = (
  id: string,
  moderationText: string
): Promise<{ id: string; moderationText: string }> => {
  return axios.patch(`/param-s/api/v1/parameters/moderation-templates/${id}`, {
    moderationText,
  });
};

export const getAModerationTemplate = (
  id: string
): Promise<IApiGet<{ _id: string; moderationText: string }>> => {
  return axios.get(`/param-s/api/v1/parameters/moderation-templates/${id}`);
};

export const deleteAModerationTemplate = (
  id: string
): Promise<{ code: string; message: string }> => {
  return axios.delete(`/param-s/api/v1/parameters/moderation-templates/${id}`);
};
