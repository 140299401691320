import isEmpty from "@/utils/isEmpty";
import { ActionReducerMapBuilder } from "@reduxjs/toolkit";
import { profile } from "console";

import { IMembersInitialState } from "./member.interfaces";

import {
  addMemberAsync,
  approuveAccountAsync,
  banProfileAsync,
  deleteAccountAsync,
  disApprouveAccountAsync,
  getFilteredProfilesAsync,
  getListAccountsMember,
  getListDeletedAccountsMember,
  getListPendingAccountsMember,
  resetMemberPasswordAsync,
  restoreMemberAccountAsync,
  toggleAccountActivenessAsync,
  unbanProfileAsync,
  switchProfileToFreeAsync,
  editMemberAsync,
  marckAsHandledAsync,
  getNbUnhandledReportsAsync,
} from "./member.services";

import { errorInitialValue } from "./member.slice";
import {IApiGet, IAPIResponseGetAllProfiles} from "@/features/backoffice/types";

const asyncActions = (
  builder: ActionReducerMapBuilder<IMembersInitialState>
) => {
  builder.addCase(getFilteredProfilesAsync.pending, (state) => {
    state.loading = "pending";
    state.errors = errorInitialValue;
  });

  builder.addCase(getFilteredProfilesAsync.fulfilled, (state, action) => {
    state.loading = "idle";
    state.errors = errorInitialValue;
    state.listProfiles = action.payload;
  });

  builder.addCase(getFilteredProfilesAsync.rejected, (state, action) => {
    state.loading = "failed";
    state.errors = action.payload;
  });

  builder.addCase(getListAccountsMember.pending, (state) => {
    state.loading = "pending";
    state.errors = errorInitialValue;
  });

  builder.addCase(getListAccountsMember.fulfilled, (state, action) => {
    state.loading = "idle";
    state.errors = errorInitialValue;
    const payload : IApiGet<IAPIResponseGetAllProfiles> =  action.payload as any;
    state.listProfiles = payload.data;
  });

  builder.addCase(getListAccountsMember.rejected, (state, action) => {
    state.loading = "failed";
    state.errors = action.payload;
  });

  builder.addCase(getListPendingAccountsMember.fulfilled, (state, action) => {
    state.loading = "idle";
    state.errors = errorInitialValue;
    state.listPendingProfiles = {
      ...action.payload,
      // data: action.payload.data.filter((data) => data.rate_of_completion >= 10),
    };
  });

  builder.addCase(getListPendingAccountsMember.rejected, (state, action) => {
    state.loading = "failed";
    state.errors = action.payload;
  });

  builder.addCase(getListDeletedAccountsMember.fulfilled, (state, action) => {
    state.loading = "idle";
    state.errors = errorInitialValue;
    state.listDeletedAccounts = action.payload;
  });

  builder.addCase(getListDeletedAccountsMember.rejected, (state, action) => {
    state.loading = "failed";
    state.errors = action.payload;
  });

  builder.addCase(approuveAccountAsync.fulfilled, (state, action) => {
    if (state.listPendingProfiles) {
      // state.listPendingProfiles.data = state.listPendingProfiles.data.filter(
      //   (member) => member.id !== action.payload.id
      // );
    }
  });

  builder.addCase(disApprouveAccountAsync.fulfilled, (state, action) => {
    if (state.listPendingProfiles) {
      // state.listPendingProfiles.data = state.listPendingProfiles.data.map(
      //   (member) =>
      //     member.id === action.payload.id
      //       ? {
      //           ...member,
      //           is_reviewed: true,
      //           account: { ...member.account, changes_requests_updated: false },
      //         }
      //       : member
      // );
    }
  });

  builder.addCase(deleteAccountAsync.fulfilled, (state, action) => {
    if (state.listProfiles) {
      state.listProfiles.result = state.listProfiles.result.filter(
        (member) => member._id !== action.meta.arg.id
      );
    }
  });

  builder.addCase(toggleAccountActivenessAsync.fulfilled, (state, action) => {
    if (state.listProfiles) {
      const index = state.listProfiles.result.findIndex(
        (member) => member._id === action.payload.data._id
      );

      state.listProfiles.result[index].activation.isActivate = action.payload.data.activation.isActivate;
    
      // if (typeof action.meta.arg.reason === "string") {
      //   state.listProfiles.result[index].activation.reason =
      //     action.meta.arg.reason;
      // } else if (
      //   state.listProfiles.result[index].activation.reason !== undefined
      // ) {
      //   state.listProfiles.result[index].activation.reason = null;
      // }

      // state.listProfiles.result[index].activation.isActivate = action.payload.data.activation.isActivate;

    }
  });

  builder.addCase(banProfileAsync.fulfilled, (state, action) => {

    if (state.listProfiles) {

      const index = state.listProfiles.result.findIndex(
        (member) => member.member._id === action.meta.arg.id
      );

      if (index != -1) {
        state.listProfiles.result[index].member.isBanned = action.payload.data
      }
    }
  });

  builder.addCase(unbanProfileAsync.fulfilled, (state, action) => {

    if (state.listProfiles) {

      const index = state.listProfiles.result.findIndex(
        (member) => member.member._id === action.meta.arg
      );

      if (index != -1) {
        console.log(index + ' ' + action.payload.data)
        state.listProfiles.result[index].member.isBanned = action.payload.data
      }
    }
  });

  builder.addCase(switchProfileToFreeAsync.fulfilled, (state, action) => {
    if (state.listProfiles) {
      const index = state.listProfiles.result.findIndex(
        (member) => member._id === action.payload.data._id
      );

      state.listProfiles.result[index] = action.payload.data;
      // state.listProfiles.result = state.listProfiles.result.map((profile) =>
      //   profile._id === action.payload._id ? action.payload : profile
      // );
      // const index = state.listProfiles.result.findIndex(
      //   (member) => member._id === action.payload.
      // );
    }
  });

  builder.addCase(resetMemberPasswordAsync.fulfilled, (state, action) => {
    if (state.listProfiles) {
      // const index = state.listProfiles.data.findIndex(
      //   (member) => member.id === action.meta.arg
      // );

      // state.listProfiles.data[index] = {
      //   ...state.listProfiles.data[index],
      //   ...action.payload,
      // };
    }
  });

  builder.addCase(restoreMemberAccountAsync.fulfilled, (state, action) => {
    if (state.listDeletedAccounts) {
      state.listDeletedAccounts.data.result = state.listDeletedAccounts.data.result.filter(
        (member) => member._id !== action.meta.arg
      );
    }
  });

  builder.addCase(addMemberAsync.fulfilled, (state, action) => {
    if (state.listProfiles) {
      state.listProfiles.result = [
        action.payload.data,
        ...state.listProfiles.result,
      ];
    }
  });

  builder.addCase(editMemberAsync.fulfilled, (state, action) => {
    if (state.listProfiles) {
      // state.listProfiles.data = state.listProfiles.data.map((profile) =>
      //   profile.id === action.payload.id ? action.payload : profile
      // );
      // state.listProfiles.data = [
      //   action.payload.data,
      //   ...state.listProfiles.data,
      // ];
    }
  });

  builder.addCase(marckAsHandledAsync.fulfilled, (state, action) => {
    state.nbUnhandledReports = state.nbUnhandledReports - 1;
    if (state.listReportedAccounts) {
      const index = state.listReportedAccounts.data.result.findIndex(
        (member) => member.id === action.payload.data._id
      );

      if (index !== -1) {
        state.listReportedAccounts.data.result[index] = {
          ...state.listReportedAccounts.data.result[index],
          isHandled: action.payload.data.isHandled,
        };
      }
    }
  });

  builder.addCase(getNbUnhandledReportsAsync.fulfilled, (state, action) => {
    state.nbUnhandledReports = action.payload.data
  })
  
  
};

export default asyncActions;
