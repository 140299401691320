import { ActionReducerMapBuilder } from "@reduxjs/toolkit";

import { IModerationInitialState } from "./moderation.interfaces";
import {
  createModerationTemplate,
  getListModerationTemplates,
  getModeratorStatusAsync,
  toggleStaffStatusAsync,
  updateModerationTemplate,
} from "./moderation.services";

import { errorInitialValue } from "./moderation.slice";
import {IApiGet, IAPIResponseGetModerationTemplate} from "@/features/backoffice/types";

const moderationAsyncActions = (
  builder: ActionReducerMapBuilder<IModerationInitialState>
) => {
  builder.addCase(getListModerationTemplates.fulfilled, (state, action) => {
    state.loading = "idle";
    state.errors = errorInitialValue;
    const payload : IApiGet<IAPIResponseGetModerationTemplate> = action.payload as any;
    state.moderationTemplates = payload.data;
  });

  builder.addCase(getListModerationTemplates.rejected, (state, action) => {
    state.loading = "failed";
    state.errors = action.payload;
  });

  builder.addCase(createModerationTemplate.fulfilled, (state, action) => {
    state.loading = "idle";
    state.errors = errorInitialValue;
    const payload : IApiGet<any> = action.payload as any;
    if (state.moderationTemplates) {
      state.moderationTemplates.result.push(payload.data);
      state.moderationTemplates.count++;
    }
  });

  builder.addCase(createModerationTemplate.rejected, (state, action) => {
    state.loading = "failed";
    state.errors = action.payload;
  });

  builder.addCase(updateModerationTemplate.fulfilled, (state, action) => {
    state.loading = "idle";
    state.errors = errorInitialValue;
    const payload : IApiGet<{_id: string, moderationText: string}> = action.payload as any;
    if (state.moderationTemplates) {
      const index = state.moderationTemplates.result.findIndex(
        (data) => data._id === payload.data._id
      );
      let data = state.moderationTemplates.result;
      data[index] = {  ...payload.data  } ;

      if (index) {
        state.moderationTemplates.result = data;
      }
    }
  });

  builder.addCase(updateModerationTemplate.rejected, (state, action) => {
    state.loading = "failed";
    state.errors = action.payload;
  });

  builder.addCase(getModeratorStatusAsync.fulfilled, (state, action) => {
    state.loading ="idle";
    state.shouldReceiveMessage = action.payload.data;
  });



  builder.addCase(toggleStaffStatusAsync.fulfilled, (state) => {
    state.loading = "idle";
    state.shouldReceiveMessage = !state.shouldReceiveMessage;
  }

  );
};

export default moderationAsyncActions;
