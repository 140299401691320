import { IAuthInitialState } from "./auth.interfaces";
import { errorInitialValue } from "./auth.slice";

export const clearAuthError = (state: IAuthInitialState) => {
  state.errors = errorInitialValue;
};

export const logout = (state: IAuthInitialState) => {
  state.user = null;
  state.loading = "idle";
  state.errors = errorInitialValue;
  state.token = "";
};

