import { axios } from "@/lib/axios";
import { TAppInterfaceSettings } from "../../types";

import {
  IAPIGetAppInterface,
  IAPIPatchAppInterface,
  IAPIPostAppInterface,
} from "../../types/interfaces/api/appInterface";

export const getInterfaceSettings = (): Promise<IAPIGetAppInterface> => {
  return axios.get("/param-s/api/v1/parameters/interface-settings");
};

export const initInterfaceSettings = (
  data: TAppInterfaceSettings
): Promise<IAPIPostAppInterface> => {
  return axios.post("/param-s/api/v1/parameters/interface-settings", {
    ...data,
  });
};

export const updateInterfaceSettings = (
  data: TAppInterfaceSettings
): Promise<IAPIPatchAppInterface> => {
  const {_id , ...body} = data;
  return axios.patch(`/param-s/api/v1/parameters/interface-settings/${_id}`, {
    ...body,
  });
};
