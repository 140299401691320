import { PayloadAction } from '@reduxjs/toolkit';
import { IModerationInitialState } from './moderation.interfaces';

export const deleteModeration = (
  state: IModerationInitialState,
  action: PayloadAction<string>
) => {
  if (state.moderationTemplates) {
    state.moderationTemplates.result = [
      ...state.moderationTemplates.result.filter(
        data => data._id !== action.payload
      ),
    ];
    state.moderationTemplates.count--;
  }
};

export const toggleReceiveMessage = (
  state: IModerationInitialState,
  action: PayloadAction<boolean>
) => {
  console.log(action.payload);
  state.shouldReceiveMessage = action.payload;
};
