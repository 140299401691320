import { axios } from "@/lib/axios";
import {IAPIGetCGU, IAPIPatchCGU, IAPIPostCGU} from "../../types";

export const getCGU = (): Promise<IAPIGetCGU> => {
  return axios.get("/param-s/api/v1/parameters/p/general-terms");
};

export const initCGU = (content: string): Promise<IAPIPostCGU> => {
  return axios.post("/param-s/api/v1/parameters/general-terms", {
    content,
  });
};

export const updateCGU = ({id  , content} : {id: string , content : string}): Promise<IAPIPatchCGU> => {
  return axios.patch(`/param-s/api/v1/parameters/general-terms/${id}`, {
    content,
  });
};
