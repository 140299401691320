import { axios } from "@/lib/axios";
import { TImamSpeakerInitialValues } from "../../containers/TeamManagement/ImamSpeakers/ImamSpeakers.container";

import {
  IAPIDeleteImamsSpeakers,
  IAPIGetImamsSpeakers,
  IAPIPatchImamsSpeakers,
  IAPIPostImamsSpeakers,
} from "../../types";

export const getImamsSpeakers = ({
  limit,
  page,
}: {
  limit?: number;
  page?: number;
} = {}) : Promise<IAPIGetImamsSpeakers> => {
  const params = limit && page ? { limit, page } : undefined;
  return axios.get("/param-s/api/v1/parameters/imam-speakers", { params });
};

export const createImamsSpeaker = (
  datas: TImamSpeakerInitialValues
): Promise<IAPIPostImamsSpeakers> => {
  const { _id, ...res } = datas;
  return axios.post("/param-s/api/v1/parameters/imam-speakers", {
    ...res,
  });
};

export const updateImamsSpeaker = (
  id: string,
  datas: TImamSpeakerInitialValues
): Promise<IAPIPatchImamsSpeakers> => {
  return axios.patch(`/param-s/api/v1/parameters/imam-speakers/${id}`, {
    ...datas,
    gender: "MALE",
  });
};

export const deleteImamsSpeaker = (
  id: string
): Promise<IAPIDeleteImamsSpeakers> => {
  return axios.delete(`/param-s/api/v1/parameters/imam-speakers/${id}`);
};
