import Axios from "axios";
import { BASE_API_URL } from "@/config";
import { persistor, store } from "@/store";
import { authSliceActions, setNewToken } from "@/store/reducers/auth/auth.slice";
import { setAuthToken } from "@/utils/setAuthToken";
import Swal from "sweetalert2";

const axiosInstance = Axios.create({
  baseURL: BASE_API_URL,
  headers: {
    "Content-Type": "application/json",
  },
  withCredentials: true,
});

axiosInstance.defaults.method = "get,post,put,delete,patch";

let isRefreshing = false;
let refreshSubscribers: Array<(token: string) => void> = [];
let failedQueue: Array<{
  resolve: (token: string) => void;
  reject: (err: any) => void;
}> = [];

const subscribeTokenRefresh = (cb: (token: string) => void) => {
  refreshSubscribers.push(cb);
};

const onRefreshed = (token: string) => {
  refreshSubscribers.forEach((cb) => cb(token));
  refreshSubscribers = [];
};

export const refreshToken = () => {
  return axiosInstance.post(
    `${BASE_API_URL}auth-s/api/v1/auth/p/rf`
  );
};

const onExpired = () => {
  console.log("Session expirée");
  Swal.fire({
    icon: "info",
    title: "Session expirée",
    text: `La session a expirée, vous devez vous reconnecter`,
    confirmButtonColor: "var(--ui-primary)",
    customClass: {
      container: "swal-custom-container",
    },
  });
  localStorage.clear();
  setAuthToken(null);
  store.dispatch(authSliceActions.logout());
  persistor.purge();
};

const processQueue = (error: any, token: string | null = null) => {
  failedQueue.forEach((prom) => {
    if (error) {
      prom.reject(error);
    } else {
      prom.resolve(token as string);
    }
  });

  failedQueue = [];
};

// Intercepteur de réponse pour gérer les erreurs 401 (non autorisé)
axiosInstance.interceptors.response.use(
  (response) => {
    return response.data;
  },
  async (error) => {
    const {
      config,
      response: { status },
    } = error;
    const originalRequest = config;


    if (status === 401 && !originalRequest._retry) {
      const currentURL = window.location.href;

      if (currentURL.indexOf("/auth/login") === -1) {
        const responseURL = error?.request?.responseURL || "";
        
        if (responseURL.indexOf("/auth/p/rf") === -1) {
          console.log('ici')
          if (isRefreshing) {
            return new Promise(function (resolve, reject) {
              failedQueue.push({ resolve, reject });
            })
              .then((token) => {
                originalRequest.headers.Authorization = `Bearer ${token}`;
                return axiosInstance(originalRequest);
              })
              .catch((err) => Promise.reject(err));
          }

          originalRequest._retry = true;
          isRefreshing = true;

          return new Promise(function (resolve, reject) {
            refreshToken()
              .then((res) => {
                const newToken = res.data;
                store.dispatch(setNewToken({ token: newToken }));
                setAuthToken(newToken);
                originalRequest.headers.Authorization = `Bearer ${newToken}`;
                processQueue(null, newToken);
                onRefreshed(newToken);
                resolve(axiosInstance(originalRequest));
              })
              .catch((err) => {
                processQueue(err, null);
                onExpired();
                reject(err);
              })
              .finally(() => {
                isRefreshing = false;
              });
          });
        } 
      }
    }

    return Promise.reject(error);
  }
);

// Intercepteur de requête pour ajouter le token d'authentification aux requêtes
axiosInstance.interceptors.request.use((config: any) => {
  const token = store.getState().auth.token;
  config.headers = {
    source: "WEB",
    ...(!window.location.href.includes("/auth/login") && {
      Authorization: token ? `Bearer ${token}` : "Bearer ",
    }),
  };


  if (["patch", "post", "put"].includes(config.method)) {
    config.headers["Content-Type"] = "application/json";
    if (config.data && !(config.data instanceof FormData)) {
      config.headers["Content-Type"] = "application/json";
    }
  }

  return config;
});

export { axiosInstance as axios };
