import { createAsyncThunk } from "@reduxjs/toolkit";
import { AxiosError } from "axios";
import to from "await-to-js";

import { askOTP, changePassword, login, verifyEmail } from "@/features/auth/api";
import { TReducerError } from "@/types/app";
import handlingError from "@/utils/handlingError";
import { IAPIResponseLogin } from "@/features/auth/types/interfaces";

export const tryLogin = createAsyncThunk<
  {
    data: string;
  },
  {
      identifier: string;
    password: string;
  },
  {
    rejectValue: TReducerError;
  }
>("auth-s/auth", async ({ identifier, password }, { rejectWithValue }) => {
  const [error, data] = await to<IAPIResponseLogin, AxiosError<any>>(
    login(identifier, password)
  );

  if (error) {
    return rejectWithValue(handlingError(error));
  }

  return data;
});

export const askOTPAsync = createAsyncThunk<
  {
    code: string;
    message: string;
  },
  string,
  {
    rejectValue: TReducerError;
  }
>("auth/askOTP", async (email, { rejectWithValue }) => {
  const [error, data] = await to<
    {
      code: string;
      message: string;
    },
    AxiosError<any>
  >(askOTP(email));

  if (error) {
    return rejectWithValue(handlingError(error));
  }

  return data;
});

export const changePasswordAsync = createAsyncThunk<
  {
    code: string;
    message: string;
  },
  { email: string; token: string; password: string },
  {
    rejectValue: TReducerError;
  }
>("auth/changePassword", async (datas, { rejectWithValue }) => {
  const [error, data] = await to<
    {
      code: string;
      message: string;
    },
    AxiosError<any>
  >(changePassword({ ...datas }));

  if (error) {
    return rejectWithValue(handlingError(error));
  }

  return data;
});

export const verifyEmailAsync = createAsyncThunk<
  {
    code: string;
    message: string;
  },
  { email: string; token: string; password: string },
  {
    rejectValue: TReducerError;
  }
>("auth/verifyEmail", async (datas, { rejectWithValue }) => {
  const [error, data] = await to<
    {
      code: string;
      message: string;
    },
    AxiosError<any>
  >(verifyEmail({ ...datas }));

  if (error) {
    return rejectWithValue(handlingError(error));
  }

  return data;
});

