import { axios } from "@/lib/axios";
import {
  IAPIGetCommunicationSMS,
  IAPIPatchPublishCommunicationSMS,
  IAPIPatchUpdateCommunicationSMS,
  IAPIPostCommunicationSMS, TGender,
} from "../../types";

export const getCommunicationSMS = ({
  limit,
  page,
}: {
  limit?: number;
  page?: number;
} = {}): Promise<IAPIGetCommunicationSMS> => {
  return axios.get(`/notif-s/bo/api/v1/notifications`, {
    params: {
      limit,
      page,
    },
  });
};

export const createCommunicationSMS = ({
  term,
  audience,
  subject,
  message,
}: {
  audience: "ALL" | TGender | "ONE";
  subject: string;
  message: string;
  term?: string;
}): Promise<IAPIPostCommunicationSMS> => {
  const payload: { [key: string]: any } = {
    audience,
    subject,
    message,
  };

  if (term) {
    payload.term = term;
  }

  return axios.post(`/notif-s/bo/api/v1/notifications/add-message`, payload);
};

export const editCommunicationSMS = ({
  communicationSMSId,
  audience,
  subject,
  message,
  ID,
}: {
  communicationSMSId: string;
  audience: "ALL" | TGender | "ONE";
  subject: string;
  message: string;
  ID?: string;
}): Promise<IAPIPatchUpdateCommunicationSMS> => {
  return axios.patch(`/notif-s/bo/api/v1/notifications/${communicationSMSId}/update-message`, {
    audience,
    subject,
    message,
    term: ID,
  });
};

export const publishCommunicationSMS = (
  communicationSMSId: string
): Promise<IAPIPatchPublishCommunicationSMS> => {
  return axios.patch(`/notif-s/bo/api/v1/notifications/${communicationSMSId}/send-message`);
};

export const deleteCommunicationSMS = (
  communicationSMSId: string
): Promise<{
  code: string;
  message: string;
}> => {
  return axios.delete(`/notif-s/bo/api/v1/notifications/${communicationSMSId}`);
};
