export const socketChannels = {
  // admin_connected_admins: 'admin/connected_admins',
  admin_connected_admins: ' get/staffs/online-count',
  get_message: 'get/messages',
  admin_new_cancellation_conversation_request:
    'admin/new-cancellation-conversation-request',
  get_modarated_message: 'get/modarated-message',
  get_moderator_message: 'get/messages',
  admin_conversation_closed: 'admin/conversation-closed',
  stop_moderation: 'handle/status',
  get_moderation_status: 'get/status',
  get_conversations_closing:  "get/conversations/closing-request",
  get_conversations_closing_confirmation: "get/conversations/closing-confirmation",
  get_interface_settings: "get/interface-settings",
  get_staffs_online_count: " get/staffs/online-count",
  get_app_planning: "get/app-planning",
  get_report_ids: "get/reportIds",
  get_token_expired: "token-expired"
};
