import { AxiosError } from "axios";

import { TReducerError } from "@/types/app";

function handlingError(error: AxiosError<any, any>) : any{
  let errorToSend: TReducerError;

  if (!error.response) {
    errorToSend = {
      message:
        "Un problème est survenu. Vérifier votre connexion par précaution.",
      value: error,
    };
  } else if (error.code === "ERR_NETWORK") {
    errorToSend = {
      message: "Une erreur réseau s'est produite, vérifiez votre connexion",
      value: error.code,
    };
  } else {
    errorToSend = {
      message: error.response.data.message,
      value: error.response,
    };
  }

  return errorToSend as any;
}

export default handlingError;
