import { createAsyncThunk } from "@reduxjs/toolkit";
import { AxiosError } from "axios";
import to from "await-to-js";

import {
  deleteAModerationTemplate,
  getAModerationTemplate,
  getModerationStatusModerator,
  getModerationTemplates,
  patchModerationTemplate,
  postModerationTemplate,
  toggleStaffStatus,
} from "@/features/backoffice/api";

import {
    IApiGet,
    IAPIResponseGetModerationTemplate,
    IAPIResponsePostModerationTemplate,
} from "@/features/backoffice/types";

import { TReducerError } from "@/types/app";

import handlingError from "@/utils/handlingError";

export const getListModerationTemplates = createAsyncThunk<
  IAPIResponseGetModerationTemplate,
  undefined,
  {
    rejectValue: TReducerError;
  }
>("moderation/getModerationTemplates", async (_param, { rejectWithValue }) => {
  const [error, data] = await to<
    IAPIResponseGetModerationTemplate,
    AxiosError<any>
  >(getModerationTemplates());

  if (error) {
    return rejectWithValue(handlingError(error));
  }

  return data;
});

export const createModerationTemplate = createAsyncThunk<
  IAPIResponsePostModerationTemplate,
  string,
  {
    rejectValue: TReducerError;
  }
>("moderation/postModerationTemplates", async (moderationText, { rejectWithValue }) => {
  const [error, data] = await to<
    IAPIResponsePostModerationTemplate,
    AxiosError<any>
  >(postModerationTemplate(moderationText));

  if (error) {
    return rejectWithValue(handlingError(error));
  }

  return data;
});

type TUpdateModerationTemplateParams = {
  id: string;
    moderationText: string;
};

export const updateModerationTemplate = createAsyncThunk<
    TUpdateModerationTemplateParams,
  TUpdateModerationTemplateParams,
  {
    rejectValue: TReducerError;
  }
>(
  "moderation/updateModerationTemplates",
  async ({ id, moderationText }, { rejectWithValue }) => {
    const [error, data] = await to<
      TUpdateModerationTemplateParams,
      AxiosError<any>
    >(patchModerationTemplate(id, moderationText));

    if (error) {
      return rejectWithValue(handlingError(error));
    }

    return data;
  }
);

export const getOneModerationTemplate = createAsyncThunk<
   IApiGet<{ _id: string; moderationText: string }>,
  string,
  {
    rejectValue:TReducerError;
  }
>("moderation/getOneModerationTemplates", async (id, {rejectWithValue}) => {
  const [error, data] = await to<IApiGet<{ _id: string; moderationText: string }>, AxiosError<any>>(
    getAModerationTemplate(id)
  );

  if (error) {
    return rejectWithValue(handlingError(error));
  }

  return data;
});

export const deleteModerationTemplate = createAsyncThunk<
  { code: string; message: string },
  string,
  {
    rejectValue: TReducerError;
  }
>("moderation/deleteModerationTemplate", async (id, { rejectWithValue }) => {
  const [error, data] = await to<
    { code: string; message: string },
    AxiosError<any>
  >(deleteAModerationTemplate(id));

  if (error) {
    return rejectWithValue(handlingError(error));
  }

  return data;
});

export const toggleReceiveMessageAsync = createAsyncThunk<
  { code: string; message: string },
  string,
  {
    rejectValue: TReducerError;
  }
  >("moderation/deleteModerationTemplate", async (id, { rejectWithValue }) => {
  const [error, data] = await to<
    { code: string; message: string },
    AxiosError<any>
  >(deleteAModerationTemplate(id));

  if (error) {
    return rejectWithValue(handlingError(error));
  }

  return data;
});

export const getModeratorStatusAsync = createAsyncThunk<
  {
    data: boolean,
    message: string
  },
  undefined,
  {
    rejectValue: TReducerError;
  }
>("member/moderatorStatus", async (values, { rejectWithValue }) => {
  const [error, data] = await to<
  {
    data: boolean,
    message: string
  },
    AxiosError<any>
  >(getModerationStatusModerator());

  if (error) {
    return rejectWithValue(handlingError(error));
  }

  return data;
});

export const toggleStaffStatusAsync = createAsyncThunk<
  {
    status: string;
  },
  boolean,
  {
    rejectValue: TReducerError;
  }
>("member/toggleStatusStaff", async (values, { rejectWithValue }) => {
  const [error, data] = await to<
    {
      status: string;
    },
    AxiosError<any>
  >(toggleStaffStatus(values));

  if (error) {
    return rejectWithValue(handlingError(error));
  }
  
  return data;
});
