import { IAPIDeletePaidServices, IAPIGetPaidServices } from "../../types/interfaces/api/paidServices";
import { TPaidServicesInitialValues } from "../../containers/Settings/PaidServices/PaidServices.container";
import { axios } from "@/lib/axios";

export const getPaidServices = (): Promise<IAPIGetPaidServices> => {
    return axios.get("/payment-s/bo/api/v1/paid-services");
};

export const createPaidService = (values: any): Promise<any> => {
    return axios.post("/payment-s/bo/api/v1/paid-services", values);
}

export const updatePaidService = (
    id: string,
    datas: Partial<TPaidServicesInitialValues>
): Promise<any> => {
    const { _id, ...rest } = datas;

    return axios.patch(`/payment-s/bo/api/v1/paid-services/${id}`, {
        ...rest,
    });
}

export const deactivatePaidService = (
    id: string
): Promise<IAPIDeletePaidServices> => {
    return axios.delete(`/payment-s/bo/api/v1/paid-services/${id}/deactivate`);
};

export const togglePaidServiceActiveness = (
    id: string
  ): Promise<IAPIDeletePaidServices> => {
    return axios.delete(`/payment-s/bo/api/v1/paid-services/${id}/activate-or-deactivate`);
  };