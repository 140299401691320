import { createAsyncThunk } from "@reduxjs/toolkit";
import { AxiosError } from "axios";
import to from "await-to-js";

import { TReducerError } from "@/types/app";

import {
  IAPIDeleteImamsSpeakers,
  IAPIGetImamsSpeakers,
  IAPIPatchImamsSpeakers,
  IAPIPostImamsSpeakers,
} from "@/features/backoffice/types";

import handlingError from "@/utils/handlingError";

import {
  createImamsSpeaker,
  deleteImamsSpeaker,
  getImamsSpeakers,
  updateImamsSpeaker,
} from "@/features/backoffice/api/staffs/imamsSpeakers.api";
import {
  TImamSpeaker,
  TImamSpeakerInitialValues,
} from "@/features/backoffice/containers/TeamManagement/ImamSpeakers/ImamSpeakers.container";

export const getImamsSpeakersAsync = createAsyncThunk<
  IAPIGetImamsSpeakers,
  { 
    limit?: number,
    page?: number,
  } | undefined,
  {
    rejectValue: TReducerError;
  }
>("imamSpeakers/get", async (_params, { rejectWithValue }) => {
  const [error, data] = await to<IAPIGetImamsSpeakers, AxiosError<any>>(
    getImamsSpeakers({limit: _params?.limit, page: _params?.page})
  );

  if (error) {
    return rejectWithValue(handlingError(error));
  }

  return data;
});

export const createImamsSpeakerAsync = createAsyncThunk<
  IAPIPostImamsSpeakers,
  TImamSpeakerInitialValues,
  {
    rejectValue: TReducerError;
  }
>("imamSpeakers/post", async (datas, { rejectWithValue }) => {
  const [error, data] = await to<IAPIPostImamsSpeakers, AxiosError<any>>(
    createImamsSpeaker(datas)
  );

  if (error) {
    return rejectWithValue(handlingError(error));
  }

  return data;
});

export const updateImamsSpeakerAsync = createAsyncThunk<
  IAPIPatchImamsSpeakers,
  { id: string; data: TImamSpeakerInitialValues },
  {
    rejectValue: TReducerError;
  }
>("imamSpeakers/update", async (values, { rejectWithValue }) => {
  const [error, data] = await to<IAPIPatchImamsSpeakers, AxiosError<any>>(
    updateImamsSpeaker(values.id, values.data)
  );

  if (error) {
    return rejectWithValue(handlingError(error));
  }

  return data;
});

export const deleteImamsSpeakerAsync = createAsyncThunk<
  IAPIDeleteImamsSpeakers,
  string,
  {
    rejectValue: TReducerError;
  }
>("imamSpeakers/delete", async (id, { rejectWithValue }) => {
  const [error, data] = await to<IAPIDeleteImamsSpeakers, AxiosError<any>>(
    deleteImamsSpeaker(id)
  );

  if (error) {
    return rejectWithValue(handlingError(error));
  }

  return data;
});
