import { verify } from "crypto";

export const routePaths = {
  root: "/",
  auth: "/auth",
  authChildren: {
    signIn: "/login",
    forgottenPassword: "/forgot-password",
    resetPassword: "/reset-password",
    verifyEmail: "/verif-email",
  },
  members: "/members",
  membersChildren: {
    profileManagement: "/profiles-management",
    profileBanishment: "/profiles-banishment",
    waitingProfile: "/waiting-profile",
    subscription: "/subscriptions-management",
    unsubscription: "/unsubscription-management",
    accountsHistory: "/accounts-history",
    reportManagement: "/report-management",
    reports: "/reports",
    memberData: "/member-data",
  },
  communications: "/communications",
  communicationChildren: {
    news: "/conversations/news",
  },
  conversations: "/conversations",
  conversationsChildren: {
    exchangeProgress: "/exchange-progress",
    moderation: "/moderation",
    mouqabala: "/mouqabala",
  },
  staffManagement: "/staff-management",
  staffManagementChildren: {
    team: "/team",
    teamMember: "/team/:idMember",
    teamMemberCategory: "/team/:idMember/:category",
    stakeholder: "/stakeholder",
    imamSpeakers: "/imam-speakers",
  },
  imamQR: "/imam-faq",
  live: "/live",
  parameters: "/parameters",
  parametersChildren: {
    interface: "/interface",
    moderation: "/moderation-availabilities",
    origins: "/origins",
    nationalities: "/nationalities",
    ethnicities: "/ethnicities",
    plans: "/plans",
    paidService: "/services",
    cgu: "/cgu",
  },
};
