import { axios } from "@/lib/axios";
import { IAPIGetSubscriptions } from "../../types";

export const getAllSubscriptions = ({
  term,
  limit,
  page,
}: {
  term?: string;
  limit?: number;
  page?: number;
} = {}): Promise<IAPIGetSubscriptions> => {
  return axios.get("/payment-s/api/v1/purchases", {
    params: {
      fields: ['email','name','phone'],
      term,
      limit,
      page,
    },
  });
};
