import { axios } from "@/lib/axios";
import {
  IAPIPatchEthnicities,
  IAPIPostEthnicities,
  IAPIResponseGetEthnicities,
} from "../../types";

export const getEthnicities = (): Promise<IAPIResponseGetEthnicities> => {
  return axios.get("/param-s/api/v1/parameters/ethnicities");
};

export const createEthnicity = ({
  name,
}: {
  name: string;
}): Promise<IAPIPostEthnicities> => {
  return axios.post("/param-s/api/v1/parameters/ethnicities", {
    name,
  });
};

export const updateEthnicity = ({
  _id,
  name,
}: IAPIPatchEthnicities): Promise<IAPIPatchEthnicities> => {
  return axios.patch(`/param-s/api/v1/parameters/ethnicities/${_id}`, {
    name,
  });
};

export const deleteEthnicity = ({_id
                                }:Pick<IAPIPatchEthnicities , "_id"> ): Promise<IAPIPatchEthnicities> => {
  return axios.delete(`/param-s/api/v1/parameters/ethnicities/${_id}`);
};